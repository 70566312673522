<template>
	<div>
		<h4 class="mb-3">最新每月推薦</h4>
		<div class="form-horizontal" v-if="announcement">
			<div class="form-group row">
				<label for="id_title" class="col-2  col-sm-1">標題</label>
				<div class="col-10 col-sm-11">
					<div class="panel panel-default">
						{{ announcement.title }}
					</div>
				</div>
			</div>
			<hr />
			<div class="form-group row mb-3">
				<label for="id_content" class="control-label col-sm-1">內容 </label>
				<div class="col-sm-11">
					<div v-html="announcement.content"></div>
				</div>
			</div>
		</div>
		<div v-else>無資料</div>
		<h4>更多每月推薦</h4>
		<div
			v-for="(announcement, key) in announcements"
			:key="key"
			class="moreRecommend"
		>
			<a tabindex="0" @click="pointer = key">{{ announcement.title }}</a>
		</div>
		<div v-if="announcements.length === 0">無資料</div>
	</div>
</template>

<script>
import { announcementAction } from "@/api/genericUser.js";

export default {
	components: {},
	data() {
		return {
			pointer: 0,
			announcements: []
		};
	},
	computed: {
		announcement() {
			let temp;
			try {
				temp = this.announcements[this.pointer];
				// temp.content = temp.content.replaceAll(/<[^>]+>/g, '<p>');
				// temp.content = temp.content.replaceAll("&nbsp;","");
			} catch (error) {
				temp = {};
			}
			return temp;
		}
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			announcementAction

				.latest({ category: "每月書訊" })
				.then(res => {
					this.announcements = [];

					res.data.forEach((v, i) => {
						this.announcements.push(v);
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss">
.moreRecommend {
	margin-bottom: 1rem;
	color: $color-water-blue;
	a:hover {
		cursor: pointer;
	}
}
</style>
